<template>
  <div>
    <!-- Hay videos de intro -->
    <VideoBackgroundVue v-show="screenState == 'videoloop'" @videoEnded="videoEnded" ref="videoBackground" :playlist="options.intro.standByVideos" />
    
    <div v-show="screenState == 'wordcloud'">
      <!-- No hay videos de intro -->
      <div v-if="options.background.video" id="videoplayer_container">
          <VideoDouob :src="options.background.video.url" :autoplay="true" :muted="true" :loop="true"/>
      </div>
      <!-- CTA GRANDE -->
      <Transition name="fade">
        <div id="ctaBig" v-if="!showNewWordBox">
          <div class="questionMark">?</div>
          <div class="cta">{{options.controller.callToAction}}</div>
        </div>
      </Transition>
      <div class="wordcloud" :style="wordcloudBackground">       
        <vue-word-cloud
          :rotation="rotation"
          :color="color"
          :spacing=".5"
          :words="cloudWords"
          :font-size-ratio="this.options.cloud.fontSizeRatio || 5"
          font-family="Work Sans, Noto Sans, Arial, sans-serif" 
        /> <!-- // No deja ingresar tipografia como var(--variable), debe ser porque hace un precalculo para saber tamaño de caracteres. -->
      </div>
      
      <!-- Caja cuando se valida una nueva frase -->
      <Transition name="fade">
        <div v-if="showNewWordBox" id="blackBg"></div>
      </Transition>
      <Transition name="fade">
        <div v-if="showNewWordBox" id="newWordBox">
          <div class="cajaCta">{{options.controller.callToAction}}</div>
          <div class="cajaFrase" :style="{ '--player-color': '#'+newWord.user.color }">
            <player-avatar :player="newWord.user" :scale="3" :showUsername="true" :horizontal="false" />
            <div>{{newWord.reaction}}</div>
          </div>
        </div>
      </Transition>
    </div>

    <!-- Popup nuevo mensaje -->
    <Transition name="slide-fade" >
    <div class="texts">
      <div v-if="showingNewMessagePopup" class="text-wrapper" :style="{borderColor: `#${newMessagePopupData.user.color}`}">
        <player-avatar :player="newMessagePopupData.user" :scale="3" :showUsername="false" :horizontal="true" />
        <div>
          <div class="username">{{ newMessagePopupData.user.username }}</div>
          <div>Envió una respuesta</div>
        </div>
      </div>
    </div>
  </Transition>

  <!-- <qr-box style="z-index: 10;" /> -->

  </div>
</template>
  
<script>
import VueWordCloud from 'vuewordcloud';
import VideoDouob from '../VideoDouob.vue';
import VideoBackgroundVue from './VideoBackground.vue';
import PlayerAvatar from "../PlayerAvatar.vue";
//import qrBox from './qrBox.vue';
import axios from 'axios';

const preposiciones = ["a","ante","bajo","con","contra","de","desde","durante","en","entre","hacia","hasta","mediante","para","por","según","sin","sobre","tras"];
const pronombres = ["Yo","mí","conmigo","me","nosotros","nosotras","nos", "tú","usted","ti","contigo","te","vosotros","vosotras","ustedes","os","el","ella","ello","sí","consigo","se","lo", "la","le","ellos", "ellas","sí","consigo","los","las","mia","mio","esta","este","estas","estos","esa","ese","esas","esos","aquella","aquel","aquellos","una","uno","alguna","alguno","un", "uno","dos","tres","cuatro","cinco","qué","quién","quiénes","cuál","cuáles","cuánto","cuánta","que","donde","dónde"];
const otrasProhibidas = [" ", "", "es", "y", "una", "sino", "al", "del", "etc", "un", "e", "e ", ".."];
const signos = ["!", "¡", ".", ",", "?", "¿", ":", "_", "-", ";"];
const banned = [...preposiciones, ...pronombres, ...otrasProhibidas]


export default {
  components: {
    //qrBox,
    PlayerAvatar,
    VideoBackgroundVue,
    [VueWordCloud.name]: VueWordCloud,
    VideoDouob
  },
  data() {
    return {
      newMessagePopupData: null,
      pendingNewMessagePopup: [],
      showingNewMessagePopup: false,
      showingNewPhraseSequence: false,
      pendingPhrasesSequence: [],
      timeouts: [],
      showNewWordBox: false,
      newWord: "",
      screenState: null,
      cloudWords: [],
      colorArr: [],
      rotationItems: {
        "horizontal": 0,
        "diagonal": 7/8,
        "horizontalvertical" : function() {
          // var chance = new Chance(word[0]);
          // return chance.pickone([0, 3/4]);
          return Math.random() > .5 ? 0 : 3/4
        },
        "asterisco": function(){
          let angulos = [0, 1/8, 3/4, 7/8]
          let rand = Math.floor(Math.random() * angulos.length)
          return angulos[rand]
        },
        "circular": function(){
          return Math.random()
        }
      }
    };
  },
  props: {
      options: {
      type: Object,
      required: true,
      },
  },
  computed: {
    ShowVideoLoop() {
      if (this.serverState < 2) return true
      return false
    },
    color: function(){
      return ([, weight]) => {
        return this.colorArr[Math.floor(Math.random()*weight) % this.colorArr.length]
      }
    },
    rotation: function(){
      // console.log("****", word)
      return this.rotationItems[this.options.cloud.rotation]
    },
    wordcloudBackground: function(){
      let styles = ''
      if (this.options.background.color) styles += `background-color: ${this.options.background.color};` 
      if (this.options.background.image) styles += `background-image: url(${encodeURI(this.options.background.image.url)});` 
      return styles
    }
  },
  methods: {
    HandleNewPopup(data){
      let timerPopupPreguntaEnviada = 5000;
      console.log("New popup", data)
      this.showingNewMessagePopup = true
      this.newMessagePopupData = data
      setTimeout(() => {
        this.showingNewMessagePopup = false
        if(this.pendingNewMessagePopup.length > 0){
          setTimeout(() => {
            this.HandleNewPopup(this.pendingNewMessagePopup.shift())
          }, 500);
        }
      }, timerPopupPreguntaEnviada);
    },

    AddNewPhraseSequence(data){
      // timers
      let timerMostrarCaja = 5000
      let timerAgregarPalabras = 2000
      let timerMostrarNube = 15000
      // Cancelo si habia un timeout de otra cosa
      this.timeouts.forEach((to)=>{
        window.clearTimeout(to)
      })
      this.showingNewPhraseSequence = true
      this.screenState = "wordcloud"
        this.newWord = data
        this.showNewWordBox = true
        setTimeout(() => {
          // oculto la caja
          this.showNewWordBox = false
        }, timerMostrarCaja);
        setTimeout(() => {
          // 3 segundos agrego nueva frase
          this.ProcesarFrase(data.reaction)
        }, timerMostrarCaja + timerAgregarPalabras);
        setTimeout(() => {
          // 10 segundos vuelvo a mostrar el videoloop
          this.screenState = "videoloop"
          this.showingNewPhraseSequence = false
          // Ahora si reviso si tenia algun pendiente en la lista y lo mando
          if(this.pendingPhrasesSequence.length > 0){
            this.AddNewPhraseSequence(this.pendingPhrasesSequence.shift())
          }else{
            this.$refs["videoBackground"].ResumePlayback()
          }
        }, timerMostrarCaja + timerAgregarPalabras + timerMostrarNube);
    },

    videoEnded(){
      let timerStandByMostrarNube = 30000
      if(this.screenState == 'wordcloud'){ return }
      this.$nextTick( () => {
        this.$refs["videoBackground"].Pause()
      })
      this.screenState = "wordcloud"
      const myTimeout = setTimeout(() => {
        this.screenState = "videoloop"
        this.$refs["videoBackground"].ResumePlayback()
      }, timerStandByMostrarNube);
      this.timeouts.push(myTimeout)
    },

    ProcesarFrase(msg, estilizarPalabras = true){
      if(estilizarPalabras) {
        msg = msg.toLowerCase() 
      }
      signos.forEach(signo => {
        msg = msg?.replaceAll(signo, "") // saco los puntos, comas y todo minuscula
      })
      let msgArr = msg?.split(" ");
      try{
        for(let i = 0; i < msgArr.length; i++){
          for(let j = 0; j < banned.length; j++){
            if(msgArr[i]){
              if(msgArr[i].localeCompare(banned[j], 'es',{sensitivity:'base'} ) == 0){
                  msgArr.splice(i,1)
              }
            }
          }
        }
        console.log("oracion limpia", msgArr)   
        msgArr.forEach(word => {
          if(word != ""){
            let wordIncluded = this.cloudWords.find(x => x[0].localeCompare(word, 'es',{sensitivity:'base'} ) == 0)
            if(wordIncluded){
                console.log("word included", wordIncluded[0])
                wordIncluded[1]++
            }else{
                this.cloudWords.push([word, 1])
            }
          }
        })
      }catch(e){
          console.warn("error agregando oracion", e)
      }
      this.cloudWords = JSON.parse(JSON.stringify(this.cloudWords));
      // guardo en localstorage
      // localStorage.setItem('words', JSON.stringify(this.words));
    },
    async LoadAllReactions(timerPausaPalabra = 2000){
      this.cloudWords = []
      // Vacio la lista y la lleno con las cargadas como default en el back
      // if(this.options.cloud.defaultWords != ""){
      //   this.cloudWords = this.options.cloud.defaultWords?.split(",").map(x => {return [x.replace(" ", ""),1]})
      // }
      this.ProcesarFrase(this.options.cloud.defaultWords, false)
      console.log("Retrieving reactions")
      let query = "/api/reactions";
      query += "?limit=999";
      query += `&where[space.slug][equals]=${this.$store.state.space.spaceSlug}` // Enviadas a este espacio
      query += "&where[type][equals]=text"; // Solo las reacciones de texto (no emojis)
      // query += "&where[hide][equals]=false" // Que no estén ocultas
      query += "&where[shownOnScreen][equals]=true" // Que hayan sido enviadas a pantall
      const res = await axios.get(process.env.VUE_APP_API_BASE + query)
      
      for(let i=0; i<res.data.docs.length; i++){
        this.ProcesarFrase(res.data.docs[i].reaction)
        await this.$timeout(timerPausaPalabra);
      }
    }
  },
  sockets: {
    SendReaction(data){
      if(data.type == "text"){
        if(this.showingNewMessagePopup){
          this.pendingNewMessagePopup.push(data)
        }else{
          this.HandleNewPopup(data)
        }
      }
    },
    ModeratedReaction(data){
      console.log("Nueva frase", data)
      if(this.options.intro.standByVideos.length > 0){
        if(this.showingNewPhraseSequence){
          // Lo agrego a la fia que despues reviso
          this.pendingPhrasesSequence.push(data)
        }else{
          // Lo agrego directo
          this.AddNewPhraseSequence(data)
        }
      }else{
        // la proceso directo
        this.ProcesarFrase(data.reaction)
      }
    },
    CleanWordCloud(){
      this.cloudWords = []
    },
    RestartWordCloud(){
      this.LoadAllReactions()
    }
  },
  mounted() {
    console.log('🌞🔅🔆☀⛅🌤🌦')
    console.log('background',this.options.background)
    this.LoadAllReactions()

    try {
      this.colorArr = JSON.parse(this.options.cloud.wordsColor)
    } catch {
      this.colorArr = [this.options.cloud.color || 'var(--primary)']
    }
    if(this.options.intro.standByVideos.length > 0){
      this.screenState = "videoloop"
    }else{
      this.screenState = "wordcloud"
    }
  },
}
</script>


<!-- CSS STYLE SIN SCOPE -->
<style lang="scss">
.cajaFrase{
  .playerAvatarContainer{
    align-items: center;
    margin-top: -35px;
    margin-bottom: 20px;
  }
  .playerAvatarContainer > .username{
    width: 500px !important;
    font-size: 24px !important;
  }
}
#newMessagePopup{
  .username{

  }
}
</style>

<!-- CSS STYLE CON SCOPE -->
<style scoped lang="scss">
@import '@/styles/variables.scss';

#blackBg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,.4);
}
.wordcloud {
  --primary-font: #{$primary-font};
  --primary: #{$primary};

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
      transition: all 1000ms;
  }
  & > div {
    height: 581px !important;
    width: 1158px !important;
    top: 100px;
  }
}
#ctaBig{
  width: 1158px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  position: fixed;
  align-items: center;
  top: 165px;
  left: 391px;
  height: 100px;
  font-size: 35px;
  border-radius: 10px;
  font-weight: bold;
  .cta{
    text-align: center;
    width: 100%;
  }
  .questionMark{
    background: rgba(255, 255, 255, 0.5);
    height: 100px;
    width: 100px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#newWordBox{
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  
  width: 700px;
  height: 300px;
  font-size: 30px;
  color: #000;
  
  
  .cajaCta{
    width: 100%;
    background: #fff;
    text-align: center;
    margin-bottom: 40px;
    padding: 10px;
    font-size: 32px;
  }
  .cajaFrase{
    width: 100%;
    background: #fff;
    text-align: center;
    padding: 10px;
    border: 3px solid;
    border-color: var(--player-color) !important;
    font-size: 40px;
  }
}

#newMessagePopup{
  position: fixed;
  bottom: 100px;
  left: 100px;
  // transform: translate(0%, -50%);
  background: #fff;
  color: #000;
  width: 600px;
  height: 100px;
  font-size: 30px;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 3px solid;
  border-color: var(--player-color) !important;
}

.texts {
  position: fixed;
  bottom: 100px;
  left: 100px;
  z-index: 9;
  display: flex;
  height: 28rem;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  overflow: hidden;

  .text-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 0.5em;
    padding: .9em 1.8em .8em .7em;
    border-radius: 10px;
    width: 30rem;
    background: $white;
    font-size: 1.5rem;
    border-style: solid;
    border-width: 4px;
    transition: all .5s;
    z-index: 0;

    .username {
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      font-size: 21px;
      line-height: 1;
      text-transform: uppercase;
    }

    .categorie {
      width: 22rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      font-size: 1.25rem !important;
      color: $grey;
    }
  }
}


.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter{
  opacity: 0;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>